import React from 'react';
import notFound from '../images/poof.png';
import '../scss/NotFound.scss';

function NotFound() {
  return (
    <div className="notfound">
        <h1>404</h1>
        <p>Not Found</p>
        <img className='notfound-image' src={notFound} alt="Not Found" />
    </div>
  );
}

export default NotFound;
