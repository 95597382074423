import React from 'react';
import '../../scss/PromotionPage.scss';
import image from '../../images/covers/death-match.png';
import audio from '../../audios/death-match.mp3';

function DeathMatch() {
  return (
    <div className="promotion-page">
      <h1 className="title">Death Match</h1>
      <div className="media">
        <img src={image} alt="Death Match Cover" className="promo-image"/>
        <audio controls src={audio} className="audio-player">
          Your browser does not support the audio element.
        </audio>
      </div>
      <button className="spotify-button">
        <a href="https://open.spotify.com/track/5NyIJmJwA6n3dddUdcuekC?si=1dac28ee251c4577" className="spotify">listen on spotify</a>
      </button>
      <button className="instagram-button">
        <a href="https://instagram.com" className="instagram">follow on instagram</a>
      </button>
    </div>
  );
}

export default DeathMatch;
