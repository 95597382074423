import React, { useState } from 'react';
import './App.css';
import pic from './images/covers/album.png';
import { Routes, Route } from 'react-router-dom';
import DeathMatch from './pages/promo/DeathMatch';
import Links from './pages/Links';
import NotFound from './pages/NotFound';
import Error508 from './pages/promo/Error508';

function App() {
  const [userInput, setUserInput] = useState('');
  const [message, setMessage] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [setKey] = useState('');

  const flex = [109, 97, 103, 110, 105, 102, 105, 99, 101, 110, 116];
  const nexus = String.fromCharCode(...flex);

  const instagramUrl = "https://instagram.com/mad.poof"; 

  const generateBase64Key = () => {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 10; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return btoa(result);
  };

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (userInput.toLowerCase() === nexus) {
      const newKey = generateBase64Key();
      setMessage(`you found the secret word: ` + 
                  `"<span style='color: #00e676;'>${nexus}</span>". ` +
                  `your key is: "<span style='color: red;'>${newKey}</span>". 
                  do not lose it until you provide it to <a href=${instagramUrl} style='color: red;'>mad.poof</a>`);
      setIsCorrect(true);
      setKey(newKey);
    } else {
      setMessage(`"${userInput}" is not the correct word.`);
      setIsCorrect(false);
    }
    setUserInput('');
  };

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={
          <div>
            <h1>madpoof</h1>
            <img className='img' src={pic} alt="mad poof" />
            <p>
              find the specific <span style={{ color: 'red' }}>secret word</span>. if you succeed and 
              follow <a href={instagramUrl} style={{ color: 'red' }}>mad.poof</a> on instagram, you will receive a reward. 
              hint 1 -&gt; replace the o's with 1's to obtain the length, and take them apart. hint 2 -&gt; 2 common points: the 1st and 2nd. 
              hint 3 -&gt; there are 2 ways to succeed.
            </p>
            <form onSubmit={handleSubmit}>
              <input 
                type="text" 
                value={userInput} 
                onChange={handleInputChange} 
                placeholder="type here..."
              />
              <button type="submit">submit</button>
            </form>
            {message && (
              <p className={isCorrect ? 'message-correct' : 'message-incorrect'} dangerouslySetInnerHTML={{__html: message}}></p>
            )}
          </div>
        }/>
        <Route path="/death-match" element={<DeathMatch />} />
        <Route path="/error508" element={<Error508 />} />
        <Route path="/links" element={<Links />} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </div>
  );
}

export default App;
